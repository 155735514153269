import React, { useState } from 'react';

import styles from './timeline.module.scss';
import TimelineSection from './TimelineSection';
import { ImageModal } from '@components';

const VerticalTimeline = ({ content }) => {
  //Modal states
  const [selectedImage, setSelectedImage] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <>
      <div className={styles.timelineContainer}>
        <ul className={styles.timeline}>
          {content.map(timePoint => (
            <TimelineSection
              data={timePoint}
              key={timePoint.id}
              open={open}
              setSelectedImage={setSelectedImage}
            />
          ))}
        </ul>
        <ImageModal
          selectedImage={selectedImage}
          showDialog={showDialog}
          close={close}
        />
      </div>
    </>
  );
};

export default VerticalTimeline;
