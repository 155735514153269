import React from "react"
import styles from "./carouselDotButton.module.scss"

const CarouselDotButton = ({ selected, onClick }) => (
  <button
    className={` ${styles.carouselDot} ${selected ? styles.isSelected : ""}`}
    type="button"
    aria-label="Navigate slide carousel"
    onClick={onClick}
  />
)
export default CarouselDotButton
