import React from "react"

const IconTwitter = ({ className }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M30 4.00652C28.1129 4.11278 28.1541 4.10411 27.9408 4.12593L29.0584 0.22621C29.0584 0.22621 25.5698 1.79421 24.6854 2.0747C22.3627 -0.47599 18.9102 -0.588689 16.442 1.26483C14.4207 2.78305 13.3358 5.39022 13.6977 8.43757C9.76387 7.7706 6.44943 5.4923 3.83263 1.65159L3.00499 0.437066L2.38655 1.82469C1.60492 3.57809 1.34537 5.5829 1.6555 7.4697C1.78276 8.24377 1.99905 8.98205 2.29866 9.67334L1.58867 9.3372L1.50444 10.7866C1.41907 12.259 1.8203 13.9777 2.5779 15.3838C2.79121 15.7798 3.06587 16.2146 3.414 16.6436L3.0471 16.5748L3.4948 18.2357C4.08325 20.4178 5.30548 22.106 6.89163 23.0445C5.30754 23.8658 4.02786 24.3899 1.92444 25.2353L0 26.0082L1.7775 27.1956C2.45522 27.6484 4.85001 29.1605 7.21619 29.614C12.4759 30.6219 18.3978 29.8011 22.3844 25.4143C25.7423 21.7193 26.8442 16.4632 26.6153 10.9936C26.5807 10.1655 26.7664 9.37523 27.1381 8.76867C27.8826 7.55415 29.9954 4.01463 30 4.00652ZM25.7325 7.48228C25.1152 8.4893 24.806 9.77541 24.8614 11.1032C25.0923 16.6204 23.8637 20.899 21.2093 23.8197C18.1087 27.2317 13.1074 28.571 7.48833 27.494C6.47072 27.2991 5.41901 26.8626 4.54857 26.4266C6.3121 25.6852 7.67395 25.0244 9.87328 23.7526L12.9435 21.9771L9.55376 21.712C7.93007 21.585 6.57806 20.6236 5.74699 19.0626C6.18828 19.0313 6.61377 18.944 7.03743 18.7997L10.2704 17.6996L7.01042 16.7241C5.42633 16.2501 4.52339 15.0921 4.0448 14.2039C3.73055 13.6203 3.52524 13.0224 3.4021 12.4712C3.72894 12.5783 4.11003 12.6547 4.72458 12.7291L7.74193 13.0937L5.35126 10.8152C3.62869 9.17361 2.93839 6.70737 3.44444 4.3365C8.82042 11.1493 15.1325 10.6373 15.7679 10.8174C15.6281 9.15767 15.6244 9.15375 15.5878 8.99575C14.7741 5.48139 16.5573 3.69694 17.3616 3.0929C19.0416 1.8314 21.7083 1.64124 23.5554 3.7196C23.9543 4.16815 24.4938 4.34461 24.9987 4.19136C25.4519 4.05378 25.8238 3.90808 26.188 3.74952L25.4299 6.39445L26.3976 6.39529C26.215 6.69451 25.9959 7.05274 25.7325 7.48228Z"
    />
  </svg>
)

export default IconTwitter
