import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styles from './categoryNavigator.module.scss';

const CategoryNavigator = () => {
  const data = useStaticQuery(graphql`
    query {
      categories: allStrapiCategory {
        edges {
          node {
            strapiId
            name
          }
        }
      }
    }
  `);
  const categories = data.categories.edges.map((edge, index) => {
    return (
      <div key={index} className={styles.categoryItem}>
        <Link
          activeClassName={styles.activeNavItem}
          to={`/knowledge/${edge.node.name}`}
        >
          {edge.node.name}
        </Link>
      </div>
    );
  });
  return (
    <div className={styles.navigatorContainer}>
      <div className={styles.header}>Categories</div>
      {categories}
    </div>
  );
};

export default CategoryNavigator;
