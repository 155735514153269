import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';

import styles from './newsletterSignupModal.module.scss';
import { NewsletterSignupForm } from '@components';
import { NewsletterModalContext } from '../../context/NewsletterModalContextProvider';
const NewletterSignupModal = () => {
  const { close, showNewsletterSignupForm } = useContext(
    NewsletterModalContext
  );
  return (
    <Dialog
      aria-labelledby='spring-modal-title'
      aria-describedby='spring-modal-description'
      className={styles.dialog}
      open={showNewsletterSignupForm}
      onClose={close}
      closeAfterTransition
      maxWidth='lg'
    >
      <NewsletterSignupForm close={close} />
    </Dialog>
  );
};

export default NewletterSignupModal;
