import React, { useState } from 'react';
import { createContext } from 'react';

export const NewsletterModalContext = createContext();

export const NewsletterModalProvider = props => {
  const [showNewsletterSignupForm, setShowNewsletterSignupForm] = useState(
    false
  );

  const open = () => setShowNewsletterSignupForm(true);
  const close = () => setShowNewsletterSignupForm(false);

  const toggleNewsletterModalHandler = () => {
    if (showNewsletterSignupForm) {
      close();
    } else {
      open();
    }
  };

  return (
    <NewsletterModalContext.Provider
      value={{
        open,
        close,
        showNewsletterSignupForm,
        toggleNewsletterModalHandler,
      }}
    >
      {props.children}
    </NewsletterModalContext.Provider>
  );
};
