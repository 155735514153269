import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import {
  IconTelegram,
  IconInstagram,
  IconTwitter,
  IconLinkedin,
} from '@components/icons';
import styles from './navBurger.module.scss';

const NavBurger = () => {
  const [showNav, setShowNav] = useState(false);
  const toggleNavBar = () => setShowNav(!showNav);

  useEffect(() => {
    if (showNav) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'static';
    }
  }, [showNav]);

  return (
    <div className={styles.burgerContainer}>
      <div
        className={`${styles.burger} ${showNav ? styles.showNav : ''}`}
        onClick={toggleNavBar}
        onKeyDown={toggleNavBar}
        role='button'
        tabIndex={0}
      >
        <div className={styles.line1}></div>
        <div className={styles.line2}></div>
        <div className={styles.line3}></div>
      </div>

      <div className={`${styles.navMenu} ${showNav ? styles.active : ''}`}>
        <ul>
          <li>
            <div
              onClick={() => {
                toggleNavBar();
              }}
              onKeyDown={() => {
                toggleNavBar();
              }}
              role='button'
              tabIndex={0}
            >
              <Link
                to='/'
                className={styles.navItem}
                activeClassName={styles.activeNavItem}
              >
                Home
              </Link>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                toggleNavBar();
              }}
              onKeyDown={() => {
                toggleNavBar();
              }}
              role='button'
              tabIndex={0}
            >
              <Link
                to='/about'
                className={styles.navItem}
                activeClassName={styles.activeNavItem}
              >
                About
              </Link>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                toggleNavBar();
              }}
              onKeyDown={() => {
                toggleNavBar();
              }}
              role='button'
              tabIndex={0}
            >
              <Link
                to='/knowledge'
                className={styles.navItem}
                activeClassName={styles.activeNavItem}
              >
                Knowledge
              </Link>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                toggleNavBar();
              }}
              onKeyDown={() => {
                toggleNavBar();
              }}
              role='button'
              tabIndex={0}
            >
              <Link
                to='/creatives'
                className={styles.navItem}
                activeClassName={styles.activeNavItem}
              >
                Creatives
              </Link>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                toggleNavBar();
              }}
              onKeyDown={() => {
                toggleNavBar();
              }}
              role='button'
              tabIndex={0}
            >
              <Link
                to='/extras'
                className={styles.navItem}
                activeClassName={styles.activeNavItem}
              >
                extras
              </Link>
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                toggleNavBar();
              }}
              onKeyDown={() => {
                toggleNavBar();
              }}
              role='button'
              tabIndex={0}
            >
              <Link
                to='/contact'
                className={styles.navItem}
                activeClassName={styles.activeNavItem}
              >
                Contact
              </Link>
            </div>
          </li>
        </ul>
        <ul className={styles.infoList}>
          <li>
            <div className={styles.infoContainer}>
              <div
                onClick={() => {
                  toggleNavBar();
                }}
                onKeyDown={() => {
                  toggleNavBar();
                }}
                role='button'
                tabIndex={0}
              >
                <Link to='/disclaimers' className={styles.activeNavItem}>
                  Disclaimers
                </Link>
              </div>
              <div
                onClick={() => {
                  toggleNavBar();
                }}
                onKeyDown={() => {
                  toggleNavBar();
                }}
                role='button'
                tabIndex={0}
              >
                <Link to='/privacy' className={styles.activeNavItem}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </li>
          <li className={styles.socialIcons}>
            <div
              onClick={() => {
                toggleNavBar();
              }}
              onKeyDown={() => {
                toggleNavBar();
              }}
              role='button'
              tabIndex={0}
            >
              <div className={styles.icons}>
                <a
                  href='https://t.me/joinchat/U6KtQgDUpV9ESn45'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconTelegram className={styles.icon} />
                </a>
                <a
                  href='https://www.instagram.com/jolyn.moh/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconInstagram className={styles.icon} />
                </a>
                <a
                  href='https://twitter.com/jolynmoh'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconTwitter className={styles.icon} />
                </a>
                <a
                  href='https://www.linkedin.com/in/jolynmoh/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconLinkedin className={styles.icon} />
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBurger;
