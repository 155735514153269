import React, { useState } from "react"
import HorizontalScroll from "react-scroll-horizontal"
import Draggable from "react-draggable"

import { IconLocation } from "@components/icons"
import styles from "./horizontalTimeline.module.scss"

const HorizontalTimeline = ({timelineData}) => {
  const content = timelineData

  const [deltaX, setDeltaX] = useState(0)
  const handleDrag = (e, data) => {
    // this.state.animValues + deltaX.
    setDeltaX(data.deltaX * 2)
  }
  return (
    <div className={styles.scrollContainer}>
      <div className={styles.scroll}>
        <span className={styles.arrow}>&#8640;</span>
        <span className={styles.text}> Scroll</span>
      </div>
      <Draggable axis="x" onDrag={handleDrag}>
        <div className={styles.dragAnchor}>
          <HorizontalScroll
            className={styles.timelineContainer}
            reverseScroll
            animValues={deltaX}
            draggable="false"
          >
            {content.map(
              ({ date, id, location, title}) => (
                <div
                  className={`${styles.timeSection}`}
                  key={id}
                  draggable="false"
                >
                  {id===1 && <div className={styles.mask} />}
                  <div className={styles.label}>
                    <h3 className={styles.title}>{title}</h3>
                    <p className={styles.date}>{date}</p>
                    <div className={styles.location}>
                      <IconLocation className={styles.icon} />
                      <p>{location}</p>
                    </div>
                  </div>
                </div>
              )
            )}
          </HorizontalScroll>
        </div>
      </Draggable>
    </div>
  )
}

export default HorizontalTimeline
