import React, { useContext } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import { HitCounter } from '@components';
import {
  IconTelegram,
  IconInstagram,
  IconTwitter,
  IconLinkedin,
} from '@components/icons';
import styles from './footer.module.scss';

import { NewsletterModalContext } from '../../context/NewsletterModalContextProvider';

const Footer = () => {
  const { toggleNewsletterModalHandler } = useContext(NewsletterModalContext);
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const scrollToTop = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerTopDivider} />
      <div
        className={styles.harpoonUpContainer}
        onClick={scrollToTop}
        onKeyDown={scrollToTop}
        role='button'
        tabIndex={0}
      >
        <button>
          back to top<span className={styles.harpoon}>&#8638;</span>
        </button>
      </div>
      <div className={styles.footerLinksContainer}>
        <div className={styles.articlesContainer}>
          <span className={styles.columnTitle}> Site Info</span>
          <Link to='/disclaimers' className={styles.article}>
            <span>Disclaimers</span>
          </Link>
          <Link to='/privacy' className={styles.article}>
            <span>Privacy Policy</span>
          </Link>
        </div>
        <div className={styles.followContainer}>
          <span className={styles.columnTitle}> Follow Me</span>
          <div className={styles.iconsContainer}>
            <a
              href='https://t.me/joinchat/U6KtQgDUpV9ESn45'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconTelegram className={styles.icon} />
            </a>
            <a
              href='https://www.instagram.com/jolyn.moh/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconInstagram className={styles.icon} />
            </a>
            <a
              href='https://twitter.com/jolynmoh'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconTwitter className={styles.icon} />
            </a>
            <a
              href='https://www.linkedin.com/in/jolynmoh/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconLinkedin className={styles.icon} />
            </a>
          </div>
        </div>
        <div className={styles.viewsContainer}>
          <span className={styles.columnTitle}> Page Views</span>
          <HitCounter slug='home' />
        </div>
        <div className={styles.newsletterContainer}>
          <span className={styles.columnTitle}> No Spam Guaranteed</span>
          <span className={styles.text}>
            How else do I tell you when I publish something new?
          </span>
          <button
            className={styles.subscribeButton}
            onClick={toggleNewsletterModalHandler}
            onKeyDown={toggleNewsletterModalHandler}
          >
            Subscribe to my newsletter
          </button>
        </div>
      </div>

      <div className={styles.footerCopyrightContainer}>
        Copyright © 2021 - All Rights Reserved @ {data.site.siteMetadata.title}
      </div>
    </footer>
  );
};

export default Footer;
