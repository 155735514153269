import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styles from './previousNextArticleCard.module.scss';

const PreviousArticleCard = ({ article, type }) => {
  const { slug, image } = article;
  return (
    <Link to={`/article/${slug}`}>
      <div className={styles.container}>
        <div className={styles.articleImage}>
          <Img fixed={image.localFile.childImageSharp.fixed} alt='Hero Image' />
        </div>
        <div className={styles.articleTextContainer}>
          <div className={styles.header}>{type} Story</div>
          <div className={styles.articleTitle}>{article.title}</div>
        </div>
      </div>
    </Link>
  );
};

export default PreviousArticleCard;
