import React from 'react';
import Img from 'gatsby-image';

import styles from './categoryCard.module.scss';
import { Link } from 'gatsby';

const CategoryCard = ({ title, image, classNames = {} }) => {
  const { categoryCardContainer, articleImage, header } = classNames;
  return (
    <Link to={`knowledge/${title}`}>
      <div
        className={`${categoryCardContainer} ${styles.categoryCardContainer}`}
      >
        <div className={`${articleImage} ${styles.articleImage}`}>
          <Img
            fluid={image.localFile.childImageSharp.fluid}
            alt='Article Image'
            imgStyle={{ objectFit: 'cover' }}
            sizes={{
              ...image.localFile.childImageSharp.fluid,
              aspectRatio: 7 / 11,
            }}
          />
        </div>
        <div className={`${header} ${styles.header}`}>{title}</div>
      </div>
    </Link>
  );
};

export default CategoryCard;
