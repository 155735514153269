import React from 'react';
import { ArticleCard } from '@components';

import styles from './tripleArticleArrangement.module.scss';
import middleArticleStyles from './middleArticle.module.scss';
import leftRightArticleStyles from './leftRightArticle.module.scss';

const TripleArticleArrangement = ({ articles }) => {
  const tripleArticles =
    articles &&
    articles
      .map((article, index) => {
        const {
          node: {
            category: { name },
            description,
            image,
            title,
            id,
            slug,
          },
        } = article;

        return (
          <ArticleCard
            key={id}
            title={title}
            description={description}
            tag={name}
            classNames={
              index === 1 ? middleArticleStyles : leftRightArticleStyles
            }
            image={image}
            url={slug}
          />
        );
      })
      .slice(0, 3);
  return (
    <div className={styles.relatedArticlesContainer}>{tripleArticles}</div>
  );
};

export default TripleArticleArrangement;
