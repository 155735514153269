import React from 'react';

const IconTwitter = ({ className }) => (
  <svg
    className={className}
    width='17'
    height='15'
    viewBox='0 0 17 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M8.25447 3.93125L8.29132 4.53679L7.67715 4.46264C5.44156 4.17841 3.4885 3.21449 1.83023 1.5956L1.01953 0.792331L0.810707 1.38551C0.368503 2.70781 0.651022 4.10426 1.57228 5.04347C2.06362 5.5625 1.95307 5.63665 1.10551 5.3277C0.810707 5.22883 0.552755 5.15469 0.528188 5.19176C0.442204 5.27827 0.737006 6.40284 0.970392 6.84773C1.28976 7.46562 1.94078 8.07116 2.65322 8.42954L3.25511 8.71378L2.54267 8.72613C1.8548 8.72613 1.83023 8.73849 1.90393 8.99801C2.1496 9.80128 3.11999 10.654 4.20094 11.0247L4.96251 11.2842L4.2992 11.6797C3.31653 12.2481 2.16189 12.5695 1.00724 12.5942C0.454487 12.6065 0 12.656 0 12.693C0 12.8166 1.49858 13.5087 2.3707 13.7805C4.98708 14.5838 8.09479 14.2378 10.4286 12.866C12.0869 11.8898 13.7452 9.94957 14.519 8.07116C14.9367 7.07017 15.3543 5.24119 15.3543 4.36378C15.3543 3.79531 15.3911 3.72117 16.079 3.04148C16.4844 2.64602 16.8652 2.2135 16.9389 2.08992C17.0617 1.85511 17.0494 1.85511 16.423 2.0652C15.3789 2.43594 15.2315 2.38651 15.7474 1.8304C16.1282 1.43494 16.5826 0.718184 16.5826 0.508099C16.5826 0.471025 16.3984 0.532815 16.1896 0.644036C15.9685 0.767616 15.4771 0.952985 15.1086 1.06421L14.4453 1.27429L13.8434 0.866479C13.5118 0.644036 13.045 0.396877 12.7993 0.322729C12.1729 0.149718 11.2148 0.174434 10.6497 0.372161C9.11431 0.928269 8.14392 2.36179 8.25447 3.93125Z'
      fill='black'
    />
  </svg>
);

export default IconTwitter;
