import React from "react"

const IconInstagram = ({ className }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M4.39453 30H25.6055C28.0286 30 30 28.0286 30 25.6055V4.39453C30 1.97136 28.0286 0 25.6055 0H4.39453C1.97136 0 0 1.97136 0 4.39453V25.6055C0 28.0286 1.97136 30 4.39453 30ZM1.75781 4.39453C1.75781 2.94067 2.94067 1.75781 4.39453 1.75781H25.6055C27.0593 1.75781 28.2422 2.94067 28.2422 4.39453V25.6055C28.2422 27.0593 27.0593 28.2422 25.6055 28.2422H4.39453C2.94067 28.2422 1.75781 27.0593 1.75781 25.6055V4.39453Z" />
    <path d="M15 22.9102C19.3616 22.9102 22.9102 19.3616 22.9102 15C22.9102 10.6384 19.3616 7.08984 15 7.08984C10.6384 7.08984 7.08984 10.6384 7.08984 15C7.08984 19.3616 10.6384 22.9102 15 22.9102ZM15 8.84766C18.3925 8.84766 21.1523 11.6075 21.1523 15C21.1523 18.3925 18.3925 21.1523 15 21.1523C11.6075 21.1523 8.84766 18.3925 8.84766 15C8.84766 11.6075 11.6075 8.84766 15 8.84766Z" />
    <path d="M23.7891 8.84766C25.2429 8.84766 26.4258 7.66479 26.4258 6.21094C26.4258 4.75708 25.2429 3.57422 23.7891 3.57422C22.3352 3.57422 21.1523 4.75708 21.1523 6.21094C21.1523 7.66479 22.3352 8.84766 23.7891 8.84766ZM23.7891 5.33203C24.2736 5.33203 24.668 5.72639 24.668 6.21094C24.668 6.69548 24.2736 7.08984 23.7891 7.08984C23.3045 7.08984 22.9102 6.69548 22.9102 6.21094C22.9102 5.72639 23.3045 5.33203 23.7891 5.33203Z" />
  </svg>
)

export default IconInstagram
