/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import 'fontsource-playfair-display';
import 'fontsource-open-sans';
import 'fontsource-peddana';
import 'fontsource-lato';

import Layout from './src/layouts/layout';
import { NewsletterModalProvider } from './src/context/NewsletterModalContextProvider';

// Wraps every page in a component
const wrapRootElement = ({ element, props }) => {
  return (
    <NewsletterModalProvider>
      <Layout {...props}>{element}</Layout>
    </NewsletterModalProvider>
  );
};

export { wrapRootElement };
