import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
} from 'react-share';

import {
  IconEmail,
  IconFacebook2,
  IconTwitter2,
  IconLinkedin,
} from '@components/icons';
import styles from './shareArticleBar.module.scss';

const ShareArticleBar = ({ shareArticleProps }) => {
  const { title, url, twitterHandle, tags } = shareArticleProps;
  return (
    <div className={styles.shareArticle}>
      <div className={styles.header}>Share this article</div>
      <div className={styles.icons}>
        <FacebookShareButton url={url} quote={title}>
          <IconFacebook2 className={styles.icon} />
        </FacebookShareButton>
        <LinkedinShareButton url={url} title={title}>
          <IconLinkedin className={styles.icon} />
        </LinkedinShareButton>
        <TwitterShareButton
          url={url}
          title={title}
          hashtags={tags}
          via={twitterHandle}
        >
          <IconTwitter2 className={styles.icon} />
        </TwitterShareButton>
        <EmailShareButton url={url} title={title} tags={tags}>
          <IconEmail className={styles.icon} />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ShareArticleBar;
