import React from 'react';
import Img from 'gatsby-image';

import styles from './imageThumbnails.module.scss';

const ImageThumbnails = ({ images, open, setSelectedImage }) => {
  const imageSelectHandler = image => {
    setSelectedImage(image);
    open();
  };

  const thumbnails = images.map((image, index) => {
    return (
      <div
        key={index}
        onClick={() => imageSelectHandler(image)}
        onKeyDown={() => imageSelectHandler(image)}
        role='button'
        tabIndex={0}
      >
        <Img
          className={styles.imageThumbnail}
          fluid={image.localFile.childImageSharp.fluid}
        />
      </div>
    );
  });

  return (
    <>
      <div
        className={styles.container}
        onClick={open}
        onKeyDown={open}
        role='button'
        tabIndex={0}
      >
        {thumbnails}
      </div>
    </>
  );
};

export default ImageThumbnails;
