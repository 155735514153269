import React from 'react';
import Img from 'gatsby-image';
import Markdown from 'react-markdown';

import { SquiglyLine, IconSprout } from '@components/icons';
import styles from './carouselSlide.module.scss';

const CarouselSlide = ({ slideData }) => {
  const {
    heroHeader: { fullSizedTitle, miniTitle },
    heroImage,
    description,
    mobileDescription,
    isAltLayout,
  } = slideData;

  return isAltLayout ? (
    <div className={styles.slideContainer}>
      <div className={styles.textContainer}>
        <h2 className={styles.jumbotronTitle}>
          <span className={styles.smallHeader}> {miniTitle} </span>
          <IconSprout /> <br />
          {fullSizedTitle}
        </h2>
        <SquiglyLine className={styles.squiglyLineDivider} />
        <span className={styles.desktop}>
          <Markdown source={description} escapeHtml={false} />
        </span>
        <span className={styles.mobile}>
          <Markdown source={mobileDescription} escapeHtml={false} />
        </span>
      </div>
      <div className={styles.imgContainer}>
        <Img fluid={heroImage.localFile.childImageSharp.fluid} alt='Hero Image' />
      </div>
    </div>
  ) : (
    <div className={styles.slideContainer}>
      <div className={styles.imgContainer}>
        <Img fluid={heroImage.localFile.childImageSharp.fluid} alt='Hero Image' />
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.jumbotronTitle}>
          <span className={styles.smallHeader}> {miniTitle} </span>
          <IconSprout /> <br />
          {fullSizedTitle}
        </h2>
        <SquiglyLine className={styles.squiglyLineDivider} />
        <span className={styles.desktop}>
          <Markdown source={description} escapeHtml={false} />
        </span>
        <span className={styles.mobile}>
          <Markdown source={mobileDescription} escapeHtml={false} />
        </span>
      </div>
    </div>
  );
};

export default CarouselSlide;
