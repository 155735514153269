import React, { useState, useRef } from 'react';
import Markdown from 'react-markdown';

import { IconClock, IconLocation, IconPaperClip } from '@components/icons';
import ImageThumbnails from '../ImageThumbnails';
import styles from './timelineSection.module.scss';

const TimelineSection = ({
  data: {
    title,
    subtitle,
    description,
    date,
    location,
    tags,
    website,
    id,
    images,
  },
  open,
  setSelectedImage,
}) => {
  //Accordian states
  const [activeClassName, setActiveClassName] = useState('');
  const [descriptHeightState, setDescriptHeightState] = useState('0px');
  const [summaryHeightState, setSummaryHeightState] = useState('0px');

  const isAccordionActive = () => activeClassName === `${styles.active}`;

  const toggleAccordion = () => {
    setActiveClassName(activeClassName === '' ? `${styles.active}` : '');

    setDescriptHeightState(
      isAccordionActive() ? '0px' : `${descriptionDiv.current.scrollHeight}px`
    );

    setSummaryHeightState(
      isAccordionActive()
        ? '0px'
        : `${summaryDetailsDiv.current.scrollHeight}px`
    );
  };

  const descriptionDiv = useRef(null);
  const summaryDetailsDiv = useRef(null);

  return (
    <li
      className={`${styles.timePoint} ${
        id === 0 ? styles.first : ''
      } ${activeClassName}`}
    >
      <div className={styles.container}>
        <div
          className={styles.summaryContainer}
          onClick={toggleAccordion}
          onKeyDown={toggleAccordion}
          role='button'
          tabIndex={0}
        >
          <div>
            <h2>{title}</h2>
            <h2 className={styles.summaryRole}>{subtitle}</h2>
            <div
              className={styles.details}
              ref={summaryDetailsDiv}
              style={{ maxHeight: `${summaryHeightState}` }}
            >
              {location && (
                <div className={styles.location}>
                  <IconLocation className={styles.icon} /> <p>{location}</p>
                </div>
              )}
              {website && (
                <div className={styles.link}>
                  <IconPaperClip className={styles.icon} /> <p>{website}</p>
                </div>
              )}

              {date && (
                <div className={styles.time}>
                  <IconClock className={styles.icon} />
                  <p className={styles.text}>{date}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.descriptContainer}>
          <div
            onClick={toggleAccordion}
            onKeyDown={toggleAccordion}
            role='button'
            tabIndex={0}
          >
            <h2 className={styles.descriptRole}>{subtitle}</h2>
          </div>
          <ul className={styles.tagList}>
            {tags.map(tag => (
              <li className={styles.tag} key={tag.id}>
                <div
                  onClick={toggleAccordion}
                  onKeyDown={toggleAccordion}
                  role='button'
                  tabIndex={0}
                >
                  {tag.tag}
                </div>
              </li>
            ))}
          </ul>
          <div
            ref={descriptionDiv}
            style={{ maxHeight: `${descriptHeightState}` }}
            className={`${styles.description} ${activeClassName}`}
          >
            <Markdown source={description} escapeHtml={false} />
            <ImageThumbnails
              images={images}
              open={open}
              setSelectedImage={setSelectedImage}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default TimelineSection;
