import React from 'react';

import styles from './quotesBar.module.scss';

const QuotesBar = ({ quote, author }) => {
  return (
    <div className={styles.anchor}>
      <span className={styles.openQuote}>“</span>
      <div className={styles.fullWidthBar}>
        <div className={styles.quote}>{quote}</div>
        <div>- {author}</div>
      </div>
      <span className={styles.closeQuote}>”</span>
    </div>
  );
};

export default QuotesBar;
