import React, { useEffect, useState, useCallback } from 'react';

import { useEmblaCarousel } from 'embla-carousel-react';

import { Jumbotron, CarouselDotButton } from '@components';
import CarouselSlide from './CarouselSlide';

import styles from './carousel.module.scss';

const Carousel = ({ carouselData }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: false,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [
    embla,
  ]);

  const slides = carouselData.map(slideData => (
    <div style={{ flex: '0 0 100%' }} key={slideData.id}>
      <CarouselSlide slideData={slideData} />
    </div>
  ));

  return (
    <>
      <Jumbotron>
        <EmblaCarouselReact style={{ height: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>{slides}</div>
        </EmblaCarouselReact>
      </Jumbotron>

      <div className={styles.carouselDots}>
        <button
          className={styles.leftButton}
          onClick={() => embla.scrollPrev()}
          disabled={!prevBtnEnabled}
        >
          &#8637;
        </button>
        {scrollSnaps.map((_, index) => (
          <CarouselDotButton
            key={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
        <button
          className={styles.rightButton}
          onClick={() => embla.scrollNext()}
          disabled={!nextBtnEnabled}
        >
          &#8640;
        </button>
      </div>
    </>
  );
};

export default Carousel;
