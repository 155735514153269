import React from 'react';
import Img from 'gatsby-image';
import Dialog from '@material-ui/core/Dialog';

import styles from './imageModal.module.scss';

const ImageModal = ({ selectedImage, showDialog, close }) => {
  return (
    <Dialog
      aria-labelledby='spring-modal-title'
      aria-describedby='spring-modal-description'
      className={styles.dialog}
      open={showDialog}
      onClose={close}
      closeAfterTransition
      maxWidth='lg'
    >
      {selectedImage && (
        <div className={styles.imageContainer}>
          <Img
            fluid={selectedImage.localFile.childImageSharp.fluid}
            className={styles.expandedImg}
          />
        </div>
      )}
    </Dialog>
  );
};

export default ImageModal;
