import React from 'react';

const PaperClip = ({ className }) => {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M18.9032 1.87596C18.1999 1.17217 17.2457 0.776897 16.2508 0.777232C15.2562 0.774886 14.302 1.17004 13.6003 1.87474L8.75829 6.71675C7.80029 7.67626 7.43405 9.07776 7.80021 10.3833C7.90741 10.7633 8.30244 10.9845 8.68251 10.8773C9.06257 10.7701 9.28378 10.3751 9.17657 9.99504C8.95084 9.18769 9.17732 8.32135 9.76929 7.72779L14.6113 2.8865C15.5165 1.98103 16.9844 1.98086 17.8899 2.88616C18.7953 3.79142 18.7955 5.25932 17.8903 6.16479L13.0483 11.0068C12.731 11.3245 12.328 11.5429 11.8886 11.6353C11.502 11.7157 11.2538 12.0944 11.3343 12.481C11.4035 12.8134 11.697 13.0513 12.0365 13.0502C12.0865 13.0503 12.1364 13.045 12.1853 13.0345C12.8954 12.8848 13.5466 12.5315 14.0593 12.0178L18.9013 7.17654C20.3655 5.71333 20.3664 3.34022 18.9032 1.87596Z'
        fill='#c08d5d'
      />
      <path
        d='M12.1788 7.63627C12.0716 7.25621 11.6766 7.035 11.2965 7.14221C10.9165 7.24942 10.6953 7.64444 10.8025 8.02451C11.0282 8.83186 10.8017 9.6982 10.2097 10.2918L5.36774 15.1338C4.46248 16.0392 2.99458 16.0394 2.08912 15.1341C1.18365 14.2288 1.18348 12.7609 2.08874 11.8555L6.93074 7.01276C7.24755 6.69511 7.65007 6.47672 8.08904 6.38426C8.47627 6.30679 8.72739 5.93012 8.64993 5.54293C8.57246 5.15574 8.19579 4.90458 7.8086 4.98205C7.80387 4.98301 7.79918 4.98397 7.79448 4.98502C7.0842 5.13496 6.43283 5.48822 5.91974 6.00176L1.07774 10.8438C-0.374997 12.3189 -0.356814 14.6924 1.11833 16.1451C2.5774 17.582 4.91947 17.5822 6.37874 16.1455L11.2207 11.3028C12.1787 10.3432 12.545 8.94175 12.1788 7.63627Z'
        fill='#c08d5d'
      />
    </svg>
  );
};

export default PaperClip;
