import React from 'react';

import styles from './fullWidthBar.module.scss';

const QuotesBar = ({ children }) => {
  return (
    <div className={styles.anchor}>
      <div className={styles.fullWidthBar}>{children}</div>
    </div>
  );
};

export default QuotesBar;
