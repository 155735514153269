import React from 'react';

import styles from "./buttonPrimary.module.scss"

const ButtonPrimary = ({children, onClick}) => {
  return (
    <button
      className={styles.buttonPrimary}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ButtonPrimary;
