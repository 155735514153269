import React from 'react';

const IconEmail = ({ className }) => {
  return (
    <svg
      className={className}
      width='15'
      height='13'
      viewBox='0 0 15 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.209 5.36517C6.0372 5.36517 0 1.94163 0 1.94163V1.40703C0 0.744631 0.5376 0.207031 1.2012 0.207031H13.2168C13.881 0.207031 14.4186 0.744631 14.4186 1.40703L14.409 2.00703C14.409 2.00703 8.4372 5.36517 7.209 5.36517Z'
        fill='black'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.209 7.01478C8.4936 7.01478 14.409 3.80664 14.409 3.80664L14.4186 11.0066C14.4186 11.669 13.881 12.2066 13.2168 12.2066H1.2012C0.5382 12.2066 0 11.669 0 11.0066L0.0096 3.80664C0.009 3.80664 6.0372 7.01478 7.209 7.01478Z'
        fill='black'
      />
    </svg>
  );
};

export default IconEmail;
