import React, { useEffect, useState } from 'react';

import styles from './sectionHeader.module.scss';

const SectionHeader = ({ data, classNames = {} }) => {
  const { subHeader } = classNames;
  const { subtitleMobile, subtitleDesktop, title } = data;

  const subHeaderText =
    useWindowWidth() < 768 ? subtitleMobile : subtitleDesktop;

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{title}</h2>
      <hr className={styles.line} />
      <p className={`${styles.subHeader} ${subHeader}`}>{subHeaderText}</p>
    </div>
  );
};

const useWindowWidth = () => {
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  useEffect(() => {
    if (!isBrowser) return false;

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
};

export default SectionHeader;
