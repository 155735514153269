import React from 'react';

import { NavBurger } from '@components';
import {
  IconTelegram,
  IconInstagram,
  IconTwitter,
  IconLinkedin,
  IconSprout,
} from '@components/icons';
import styles from './header.module.scss';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.links}>
        <div className={styles.singapore}>Singapore</div>
        <div className={styles.icons}>
          <a
            href='https://t.me/joinchat/U6KtQgDUpV9ESn45'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconTelegram className={styles.icon} />
          </a>
          <a
            href='https://www.instagram.com/jolyn.moh/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconInstagram className={styles.icon} />
          </a>
          <a
            href='https://twitter.com/jolynmoh'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconTwitter className={styles.icon} />
          </a>
          <a
            href='https://www.linkedin.com/in/jolynmoh/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconLinkedin className={styles.icon} />
          </a>
        </div>
        <NavBurger />
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <h1 className={styles.text}>Jolyn Moh</h1>
          <IconSprout />
        </div>

        <div className={styles.quote}>
          "Life is a series of baby steps." - Hoda Kotb
        </div>
      </div>
    </header>
  );
};

export default Header;
