import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './fullWidthBar.module.scss';

const FullWidthBarWithLeaves = ({ children, classNames = {} }) => {
  const query = useStaticQuery(graphql`
    query {
      pic1: file(relativePath: { eq: "small-leaves.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { innerContainer, leaf1, leaf2 } = classNames;

  return (
    <>
      <div className={styles.anchor}>
        <div className={`${innerContainer} ${styles.innerContainer}`}>
          {children}
        </div>
        <div className={styles.fullWidthBar}>
          <div className={`${leaf1} ${styles.leaf1}`}>
            <Img fluid={query.pic1.childImageSharp.fluid} alt='Small leaves' />
          </div>
          <div className={`${leaf2} ${styles.leaf2}`}>
            <Img
              fluid={query.pic1.childImageSharp.fluid}
              alt='Small leaves'
              className={styles.leaf2}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FullWidthBarWithLeaves;
