import React from 'react';
import Lottie from 'react-lottie';

import animationData from '@images/svg/lotties/astronaut.json';
import { ButtonPrimary } from '@components';
import styles from './successPanel.module.scss';

const SuccessPanel = ({ close }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.descriptionContainer}>
          <div className={styles.header}>You are now subscribed!</div>
          <div className={styles.description}>
            <p>Congratulations on joining this community!</p>
            <p>
              You will receive updates when a new post is published. Till then,
              have a good life!
            </p>
          </div>
          <ButtonPrimary className={styles.button} onClick={close}>
            Of course!
          </ButtonPrimary>
        </div>
        <div className={styles.lottieContainer}>
          <Lottie options={defaultOptions} />
        </div>
      </div>
    </>
  );
};

export default SuccessPanel;
