import React from 'react';

const IconClock = ({ className }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M10 0C4.4861 0 0 4.4861 0 10C0 15.5139 4.4861 20 10 20C15.5139 20 20 15.5139 20 10C20 4.4861 15.5139 0 10 0ZM10 18.75C5.17517 18.75 1.25001 14.8248 1.25001 10C1.25001 5.17517 5.17517 1.25001 10 1.25001C14.8248 1.25001 18.75 5.17517 18.75 10C18.75 14.8248 14.8248 18.75 10 18.75Z'
        fill='#c08d5d'
      />
      <path
        d='M10.625 3.75H9.375V10.2588L13.3081 14.1919L14.1919 13.3081L10.625 9.74119V3.75Z'
        fill='#c08d5d'
      />
    </svg>
  );
};

export default IconClock;
