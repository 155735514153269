import { Link } from 'gatsby';
import React from 'react';

import styles from './topArticles.module.scss';

const TopArticles = ({ articles }) => {
  const articleList = articles.map((article, index) => {
    const {
      node: { title, slug },
    } = article;

    return (
      <Link to={`/article/${slug}`}>
        <div className={styles.articleListItem}>
          <span className={styles.articleNumber}>{index + 1}</span>
          <span className={styles.articleTitle}>{title}</span>
        </div>
      </Link>
    );
  });

  return (
    <div>
      <div className={styles.header}> Most Popular</div>
      <div className={styles.articleList}>{articleList}</div>
    </div>
  );
};

export default TopArticles;
