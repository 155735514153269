import React, { useState } from 'react';
import Lottie from 'react-lottie';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import addToMailchimp from 'gatsby-plugin-mailchimp';

import styles from './newsletterSignupForm.module.scss';
import animationData from '@images/svg/lotties/puzzle.json';
import { ButtonPrimary } from '@components';
import { IconCross } from '@components/icons';

import SuccessPanel from './SuccessPanel';
import FailurePanel from './FailurePanel';

const NewsletterSignupForm = ({ close }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isInterestedInTech, setInterestedInTech] = useState(true);
  const [isInterestedInBusiness, setInterestedInBusiness] = useState(true);
  const [isInterestedInFinance, setInterestedInFinance] = useState(true);
  const [isInterestedInLearning, setInterestedInLearning] = useState(true);

  //Status or error messages for form submission
  let [formStatusMsg, setFormStatusMsg] = useState('');
  let [formStatus, setFormStatus] = useState('default');

  const handleEmailInputChange = event => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleNameInputChange = event => {
    const value = event.target.value;
    setName(value);
  };

  const handleIsInterestedInTech = event => {
    console.log(event);
    setInterestedInTech(event.target.checked);
  };

  const handleIsInterestedInBusiness = event => {
    setInterestedInBusiness(event.target.checked);
  };

  const handleIsInterestedInFinance = event => {
    setInterestedInFinance(event.target.checked);
  };

  const handleIsInterestedInLearning = event => {
    setInterestedInLearning(event.target.checked);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await addToMailchimp(email, {
      FNAME: name,
      'group[106466][1]': isInterestedInTech,
      'group[106466][2]': isInterestedInBusiness,
      'group[106466][4]': isInterestedInFinance,
      'group[106466][8]': isInterestedInLearning,
    });
    setFormStatusMsg(response.msg);
    if (response.result === 'error') {
      setFormStatus('error');
    }
    if (response.result === 'success') {
      setFormStatus('success');
    }
  };

  const signupForm = () => (
    <div className={styles.container}>
      <div className={styles.lottieContainer}>
        <Lottie options={defaultOptions} />
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.header}>You are the missing piece!</div>
        <div className={styles.description}>
          <p>
            My goal with this site is to create genuine and value-adding content
            for personal development or perhaps, just a pleasurely read.
          </p>
          <p>
            I want to update you when I do. You may pick your option of article
            type or just have general updates when a new page is up.
          </p>
          <p>No spam, unsubscribe at any time.</p>
        </div>
      </div>
      <div className={styles.nameInputContainer}>
        <span className={styles.groupHeader}> First Name </span>
        <Input
          id='standard-basic'
          value={name}
          onChange={handleNameInputChange}
          type='text'
          required
          className={styles.textField}
        />
      </div>
      <div className={styles.emailInputContainer}>
        <span className={styles.groupHeader}> Email </span>
        <Input
          id='standard-basic'
          value={email}
          onChange={handleEmailInputChange}
          type='email'
          required
          className={styles.textField}
        />
      </div>
      <div className={styles.radioGroupContainer}>
        <span className={styles.groupHeader}>
          I am interested in... (optional)
        </span>
        <div className={styles.checkboxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isInterestedInTech}
                onChange={handleIsInterestedInTech}
                name='checked'
                color='primary'
                className={styles.checkbox}
              />
            }
            label='Tech'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isInterestedInBusiness}
                onChange={handleIsInterestedInBusiness}
                name='checked'
                color='primary'
                className={styles.checkbox}
              />
            }
            label='Business'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isInterestedInFinance}
                onChange={handleIsInterestedInFinance}
                name='checked'
                color='primary'
                className={styles.checkbox}
              />
            }
            label='Personal Finance'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={isInterestedInLearning}
                onChange={handleIsInterestedInLearning}
                name='checked'
                color='primary'
                className={styles.checkbox}
              />
            }
            label='Learning'
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonPrimary>Subscribe</ButtonPrimary>
      </div>
    </div>
  );

  const renderContents = () => {
    if (formStatus === 'default') {
      return signupForm();
    } else if (formStatus === 'success') {
      return <SuccessPanel close={close} />;
    } else {
      return <FailurePanel close={close} errorMsg={formStatusMsg} />;
    }
  };

  return (
    <form
      className={styles.form}
      name='subscribeForm'
      method='POST'
      onSubmit={handleSubmit}
    >
      <div className={styles.borderContainer}>
        <div
          onClick={close}
          className={styles.iconContainer}
          onKeyDown={close}
          role='button'
          tabIndex={0}
        >
          <IconCross className={styles.icon} />
        </div>
        {renderContents()}
      </div>
    </form>
  );
};

export default NewsletterSignupForm;
