import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';

import styles from './hitCounter.module.scss';

const HitCounter = ({ slug }) => {
  const [hits, setHits] = React.useState(undefined);

  useEffect(() => {
    // Don't count hits on localhost
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    // Invoke the function by making a request.
    fetch(`/.netlify/functions/registerHit?slug=${slug}`)
      .then(res => res.json())
      .then(json => {
        if (typeof json.hits === 'number') {
          setHits(json.hits);
        }
      });
  }, [slug]);

  return (
    <div className={styles.views}>
      <span className={styles.hits}>
        <NumberFormat
          thousandSeparator
          displayType='text'
          value={hits || 25030}
        />
      </span>
      views <br /> since Feb 2022
    </div>
  );
};

export default HitCounter;
