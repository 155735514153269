import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import styles from './navbar.module.scss';

const Navbar = ({ siteTitle }) => {
  return (
    <div className={styles.navBar}>
      <div className={styles.navMenu}>
        <ul>
          <Link
            to='/'
            className={styles.navItem}
            activeClassName={styles.activeNavItem}
          >
            <li>Home</li>
          </Link>
          <Link
            to='/about'
            className={styles.navItem}
            activeClassName={styles.activeNavItem}
          >
            <li>About</li>
          </Link>
          <Link
            to='/knowledge'
            className={styles.navItem}
            activeClassName={styles.activeNavItem}
          >
            <li>Knowledge</li>
          </Link>
          <Link
            to='/creatives'
            className={styles.navItem}
            activeClassName={styles.activeNavItem}
          >
            <li>Creatives</li>
          </Link>
          <Link
            to='/extras'
            className={styles.navItem}
            activeClassName={styles.activeNavItem}
          >
            <li>Extras</li>
          </Link>
          <Link
            to='/contact'
            className={styles.navItem}
            activeClassName={styles.activeNavItem}
          >
            <li>Contact</li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  siteTitle: PropTypes.string,
};

Navbar.defaultProps = {
  siteTitle: ``,
};

export default Navbar;
