import React from 'react';
import Img from 'gatsby-image';

import styles from './articleCard.module.scss';
import ArticleTag from './ArticleTag';
import { Link } from 'gatsby';

const ArticleCard = ({
  url,
  title,
  description,
  tag,
  image,
  classNames = {},
  hideTags = false,
}) => {
  const {
    articleCardContainer,
    articleImage,
    header,
    descriptionContainer,
    tags,
  } = classNames;
  return (
    <Link to={`article/${url}`}>
      <div className={`${articleCardContainer} ${styles.articleCardContainer}`}>
        <div className={`${articleImage} ${styles.articleImage}`}>
          <Img
            fluid={image.localFile.childImageSharp.fluid}
            alt='Article Image'
            imgStyle={{ objectFit: 'cover' }}
            sizes={{
              ...image.localFile.childImageSharp.fluid,
              aspectRatio: 7 / 10,
            }}
          />
        </div>
        <div className={`${header} ${styles.header}`}>{title}</div>
        <div
          className={`${descriptionContainer} ${styles.descriptionContainer}`}
        >
          {description}
        </div>
        {!hideTags && (
          <div className={`${tags} ${styles.tags}`}>
            Posted under{' '}
            <Link to={`/knowledge/${tag}`}>
              <ArticleTag tag={tag} />
            </Link>
          </div>
        )}
      </div>
    </Link>
  );
};

export default ArticleCard;
