import React from 'react';

const IconFacebookFilled = ({ className }) => {
  return (
    <svg
      className={className}
      width='9'
      height='19'
      viewBox='0 0 9 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.98461 18.207V9.20598H8.64715L9 6.10416H5.98461L5.98913 4.55168C5.98913 3.74268 6.0715 3.3092 7.31663 3.3092H8.98115V0.207031H6.31823C3.11963 0.207031 1.9938 1.71174 1.9938 4.2422V6.10452H0V9.20633H1.9938V18.207H5.98461Z'
        fill='black'
      />
    </svg>
  );
};

export default IconFacebookFilled;
