import React from "react"

const SquiglyLine = ({className}) => (
  <svg
    width="169"
    height="13"
    viewBox="0 0 169 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M167 11.0048C162 11.0048 148.606 2.00475 136 2.00475C123.394 2.00475 117.593 11.5699 105 11.0048C94.0815 10.5148 89.4269 2.23995 78.5 2.00475C66.831 1.75358 61.6593 11.5434 50 11.0048C39.8179 10.5344 35.692 1.86508 25.5 2.00475C15.6736 2.13941 2 11.0048 2 11.0048"
      stroke="#3F4042"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SquiglyLine
