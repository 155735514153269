import React from 'react';
import styles from './achievementTile.module.scss';

const AchievementTile = ({ number, description, examples }) => (
  <div className={styles.container}>
    <div className={styles.number}>{number}</div>
    <div className={styles.description}>{description}</div>
    <div className={styles.contentDetails}>
      {examples.map(eg => (
        <p className={styles.examples} key={eg.id}>
          {eg.listItem}
        </p>
      ))}
    </div>
  </div>
);

export default AchievementTile;
