import React, { useContext } from 'react';
import Lottie from 'react-lottie';

import styles from './workInProgress.module.scss';
import animationData from '@images/svg/lotties/Coffee.json';

import { NewsletterModalContext } from '../../context/NewsletterModalContextProvider';

const WorkInProgress = () => {
  const { toggleNewsletterModalHandler } = useContext(NewsletterModalContext);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <div className={styles.descriptionContainer}>
        <p className={styles.description}>
          Oops! This page is still in progress. <br />
          Building a website is harder than I thought? <br />
          Subscribe to receive news when the site is published.
        </p>
        <div className={styles.buttonsContainer}>
          <button
            className={styles.subscribeButton}
            onClick={toggleNewsletterModalHandler}
          >
            Type email here!
          </button>
          <button className={styles.subscribeButton}>
            <a
              href='https://www.paypal.com/paypalme/jolynmoh'
              target='_blank'
              rel='noopener noreferrer'
            >
              Buy me coffee
            </a>
          </button>
        </div>

        <div className={styles.lottieSection}>
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      </div>
    </>
  );
};

export default WorkInProgress;
