import React from 'react';

import styles from './articleTag.module.scss';

const ArticleTag = ({ tag, isActive }) => {
  const classNames = isActive ? `${styles.tag} ${styles.active}` : styles.tag;

  return <div className={classNames}>{tag}</div>;
};

export default ArticleTag;
