import React from 'react';
import Lottie from 'react-lottie';

import animationData from '@images/svg/lotties/astronaut.json';
import { ButtonPrimary } from '@components';
import styles from './failurePanel.module.scss';

const FailurePanel = ({ close, errorMsg }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.descriptionContainer}>
          <div className={styles.header}>An error occured!</div>
          <div className={styles.description}>
            <p dangerouslySetInnerHTML={{ __html: errorMsg }}></p>
          </div>
          <ButtonPrimary className={styles.button} onClick={close}>
            Close this window
          </ButtonPrimary>
        </div>
        <div className={styles.lottieContainer}>
          <Lottie options={defaultOptions} />
        </div>
      </div>
    </>
  );
};

export default FailurePanel;
