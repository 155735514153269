import React from 'react';

import PreviousNextArticleCard from './PreviousNextArticleCard';
import styles from './articleNavigatorBar.module.scss';
import { ShareArticleBar } from '@components';

const ArticleNavigatorBar = ({
  nextArticle,
  previousArticle,
  shareArticleProps,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.prevArticle}>
        <PreviousNextArticleCard article={previousArticle} type='Previous' />
      </div>
      <div className={styles.shareArticle}>
        <ShareArticleBar shareArticleProps={shareArticleProps} />
      </div>
      <div className={styles.nextArticle}>
        <PreviousNextArticleCard article={nextArticle} type='Next' />
      </div>
    </div>
  );
};

export default ArticleNavigatorBar;
