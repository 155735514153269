import React from 'react';

import styles from './accomplishments.module.scss';
import { AchievementTile } from '@components';

const Accomplishments = ({ accomplishmentsData }) => {
  const accomplishments = accomplishmentsData.map(accomp => (
    <AchievementTile
      key={accomp.id}
      number={accomp.count}
      description={accomp.description}
      examples={accomp.list}
    />
  ));

  return (
    <>
      <div className={styles.achievements}>{accomplishments}</div>
    </>
  );
};

export default Accomplishments;
