import React from "react"

const IconLinkedin = ({ className }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M25.6055 0H4.39453C1.97136 0 0 1.97136 0 4.39453V25.6055C0 28.0286 1.97136 30 4.39453 30H25.6055C28.0286 30 30 28.0286 30 25.6055V4.39453C30 1.97136 28.0286 0 25.6055 0ZM28.2422 25.6055C28.2422 27.0593 27.0593 28.2422 25.6055 28.2422H4.39453C2.94067 28.2422 1.75781 27.0593 1.75781 25.6055V4.39453C1.75781 2.94067 2.94067 1.75781 4.39453 1.75781H25.6055C27.0593 1.75781 28.2422 2.94067 28.2422 4.39453V25.6055Z" />
    <path d="M5.33203 24.7266H10.6055V12.3047H5.33203V24.7266ZM7.08984 14.0625H8.84766V22.9688H7.08984V14.0625Z" />
    <path d="M19.3996 12.3047C19.398 12.3047 19.3961 12.3047 19.3945 12.3047C18.7868 12.3047 18.195 12.4063 17.6367 12.6036V12.3047H12.3633V24.7266H17.6367V18.457C17.6367 17.9725 18.0311 17.5781 18.5156 17.5781C19.0002 17.5781 19.3945 17.9725 19.3945 18.457V24.7266H24.668V17.8528C24.668 15.0082 22.3604 12.3074 19.3996 12.3047ZM22.9102 22.9688H21.1523V18.457C21.1523 17.0032 19.9695 15.8203 18.5156 15.8203C17.0618 15.8203 15.8791 17.0032 15.8789 18.4568V22.9688H14.1211V14.0625H15.8789V15.8215L17.2856 14.7649C17.8974 14.3053 18.6266 14.0625 19.3945 14.0625H19.398C21.2688 14.0643 22.9102 15.8354 22.9102 17.8528V22.9688Z" />
    <path d="M5.33203 10.5469H10.6055V5.27344H5.33203V10.5469ZM7.08984 7.03125H8.84766V8.78906H7.08984V7.03125Z" />
  </svg>
)

export default IconLinkedin
