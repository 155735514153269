import React from 'react';
import styles from './Button.module.scss';

const Button = React.forwardRef(
  ({ title, arrowDirection = 'right', onClick }, ref) => {
    return (
      <button
        className={` ${styles.btnBackgroundSlide} ${
          arrowDirection === 'right' ? '' : styles.down
        }`}
        ref={ref}
        onClick={onClick}
      >
        {title}
      </button>
    );
  }
);

export default Button;
